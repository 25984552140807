define("discourse/plugins/vex-discourse-oauth2/discourse/initializers/vex-sso", ["exports", "discourse/lib/plugin-api", "discourse/lib/logout"], function (_exports, _pluginApi, _logout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const REDIRECT_URL = "https://pd.vex.com";
  function myLogout(currentUser, noRetry) {
    if (currentUser) {
      currentUser.destroySession().then(response => (0, _logout.default)({
        redirect: REDIRECT_URL
      })).catch(err => {
        if (!noRetry) {
          myLogout(currentUser, true);
        }
      });
    }
  }
  var _default = _exports.default = {
    name: "vex-sso",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.11.7", api => {
        const currentUser = api.getCurrentUser();
        if (currentUser) {
          console.log("checking user groups");
          const groups = currentUser.groups.map(group => group.name);
          const isAdmin = currentUser.admin;
          const isStaff = currentUser.staff;
          const hasVex = groups.some(group => group.startsWith("vex"));
          console.log(`current user is admin: ${isAdmin} is staff: ${isStaff} is licensed: ${hasVex}`);
          const hasAccess = isAdmin || isStaff || hasVex;
          if (!hasAccess) {
            console.warn("current user does not have access. redirect to community site");
            myLogout(currentUser);
          }
        } else {
          console.warn("no user logged in");
        }
        // window.dapi = api
      });
    }
  };
});